@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;400;500;600;700&display=swap');

body,
html {
  height: 100%;
}

@layer base {
  h1 {
    @apply text-2xl py-2;
  }
  h2 {
    @apply text-xl py-2;
  }
  h3 {
    @apply text-lg py-2;
  }
  p {
    @apply py-2;
  }
  ul {
    @apply list-disc list-inside;
  }
}
